{
  "addItem": "Add {{item}}",
  "addTestStockShot": "Add Test Stock Shot",
  "allItem": "All {{item}}",
  "backhand": "Backhand",
  "begin": "Begin",
  "cancel": "Cancel",
  "class": "Class",
  "collection": "Collection",
  "complete": "Complete",
  "confirm": {
    "delete": {
      "type": "<1>Are you sure you want to delete this {{type}}?</1><1>This action cannot be undone.</1>",
      "item": "<1>Are you sure you wish to delete <0>{{item}}</0>?</1><1>This action cannot be undone.</1>",
      "title": "Delete {{type}}",
      "yes": "Yes, Delete"
    }
  },
  "connect": "Connect",
  "create": "Create",
  "createDisc": "Create a Disc",
  "createDiscDescription": "Select a class to begin creating your first disc.",
  "createItem": "Create {{item}}",
  "customize": "Customize",
  "delete": "Delete",
  "deleteItem": "Delete {{item}}",
  "deletingItem": "Deleting {{item}}",
  "devices": "Devices",
  "disc": "Disc",
  "discardChanges": "Discard Changes",
  "disc_capital_one": "Disc",
  "disc_capital_other": "Discs",
  "disc_one": "disc",
  "disc_other": "discs",
  "confirmStockShotCancel": "Exit Stock Shot creation?",
  "loseProgressMessage": "You will lose all progress made, this action cannot be undone.",
  "discAppearances": {
    "burst": "Burst",
    "overmold": "Overmold",
    "solid": "Solid"
  },
  "discClass": {
    "casual": {
      "control_one": "control driver",
      "control_other": "control drivers",
      "distance_one": "distance driver",
      "distance_other": "distance drivers",
      "fairway_one": "fairway driver",
      "fairway_other": "fairway drivers",
      "midrange_one": "midrange",
      "midrange_other": "midranges",
      "putt-approach_one": "putt/approach",
      "putt-approach_other": "putt/approaches",
      "putter_one": "putter",
      "putter_other": "putters"
    },
    "proper": {
      "control_one": "Control Driver",
      "control_other": "Control Drivers",
      "distance_one": "Distance Driver",
      "distance_other": "Distance Drivers",
      "fairway_one": "Fairway Driver",
      "fairway_other": "Fairway Drivers",
      "midrange_one": "Midrange",
      "midrange_other": "Midranges",
      "putt-approach_one": "Putt/Approach",
      "putt-approach_other": "Putt/Approaches",
      "putter_one": "Putter",
      "putter_other": "Putters"
    }
  },
  "discCollection": "Disc Collection",
  "discFlightPlate": {
    "dome": "Dome",
    "flat": "Flat",
    "neutral": "Neutral",
    "puddle": "Puddle"
  },
  "shotTypes": {
    "hyzer": "Hyzer",
    "neutral": "Neutral (Flat)",
    "anhyzer": "Anhyzer",
    "grenade": "Grenade",
    "thumber": "Thumber",
    "tomahawk": "Tomahawk",
    "roller": "Roller"
  },
  "disconnect": "Disconnect",
  "discPreset": {
    "feedback": {
      "required": {
        "nickname": "You must provide a name for your disc."
      },
      "noStockShots_title": "Would you like to preview the flight path of your discs?",
      "noStockShots_message": "Try our <0>Stock Shots</0> feature!  By defining your stock shots, you're able to tune your discs closer to their real flight characteristics."
    },
    "instructions": {
      "chooseFlightNumbers": "Decide how your disc flies! These metrics will change the physics of simulated flights.",
      "previewFlight": "Preview the flight path of your disc with your stock shots.",
      "customize": "Define your discs unique characteristics and distinguish it amongst others in your bag!",
      "discNameAutoFill": "Leave this field blank to auto-fill based on plastic/mold.",
      "review": "Confirm everything looks right and send it!"
    },
    "notification": {
      "create_error": "We're having an issue creating this disc, please try again later.",
      "created": "<0>{{discName}}</0> added to {{discClass}}.",
      "deleted": "<0>{{discName}}</0> has been deleted.",
      "updated": "<0>{{discName}}</0> updated."
    }
  },
  "discWear": {
    "field": "Field Tested",
    "new": "New",
    "seasoned": "Seasoned"
  },
  "editDisc": "Edit Disc",
  "experimentalLab": "Experimental Lab",
  "finish": "Finish",
  "flightNumbers": {
    "fade": {
      "full": "Fade",
      "short": "FAD",
      "upper": "FADE"
    },
    "glide": {
      "full": "Glide",
      "short": "GLD",
      "upper": "GLIDE"
    },
    "label_capital_one": "Flight Number",
    "label_capital_other": "Flight Numbers",
    "label_one": "flight number",
    "label_other": "flight numbers",
    "speed": {
      "full": "Speed",
      "short": "SPD",
      "upper": "SPEED"
    },
    "turn": {
      "full": "Turn",
      "short": "TUR",
      "upper": "TURN"
    },
    "weight": {
      "full": "Weight",
      "short": "WGT",
      "upper": "WEIGHT"
    }
  },
  "flightPath": {
    "feedback": {
      "calculating": "Calculating flight path...",
      "controls": "Use the controls to change the view of the flight path."
    }
  },
  "flightPreview": "Flight Preview",
  "flightPath_capital_one": "Flight Path",
  "flightPath_capital_other": "Flight Paths",
  "flightPath_one": "flight path",
  "flightPath_other": "flight paths",
  "flightPlate": "Flight Plate",
  "forehand": "Forehand",
  "gettingStarted": "Getting Started",
  "inspect_user": "Inspect User",
  "instructions": {
    "selectAllClipboard": "Select all and copy to clipboard!"
  },
  "lab": "Lab",
  "leaderboards": "Leaderboards",
  "left": "LEFT",
  "manufacturer": "Manufacturer",
  "messages": {
    "copiedClipboard": "Copied to clipboard!"
  },
  "video_capital_one": "Video",
  "metrics": {
    "advanceRatio": {
      "full": "Advance Ratio",
      "short": "ADV",
      "upper": "ADV"
    },
    "apex": {
      "full": "Apex",
      "short": "APX",
      "upper": "APEX"
    },
    "distance": {
      "full": "Distance",
      "short": "DST",
      "upper": "DISTANCE"
    },
    "hyzer": {
      "full": "Hyzer",
      "short": "HZR",
      "upper": "HYZER"
    },
    "launch": {
      "full": "Launch",
      "short": "LNH",
      "upper": "LAUNCH"
    },
    "nose": {
      "full": "Nose",
      "short": "NS",
      "upper": "NOSE"
    },
    "speed": {
      "full": "Speed",
      "short": "SPD",
      "upper": "SPEED"
    },
    "spin": {
      "full": "Spin",
      "short": "SPN",
      "upper": "SPIN"
    },
    "wobble": {
      "full": "Wobble",
      "short": "WBL",
      "upper": "WOBBLE"
    }
  },
  "mold": "Mold",
  "nevermind": "Nevermind",
  "next": "Next",
  "nickname": "Nickname",
  "plastic": "Plastic",
  "readyToThrow": "In Progress",
  "researchLab": "Research Lab",
  "restart": "Restart",
  "review": "Review",
  "right": "RIGHT",
  "save": "Save",
  "seeAll": "See All",
  "selectItem": "Select {{item}}",
  "sessionThrows": "Session Throws",
  "setup": "Setup",
  "share": "Share",
  "sharedThrowLinkWarning": "Sharing will set the throw to public, making it accessible to anyone with the link.",
  "sharedThrowSetLinkWarning": "Sharing will set the throw set to public, making it accessible to anyone with the link.",
  "shareThrowDialogTitle": "Share your throw",
  "shareThrowSetDialogTitle": "Share your throw set",
  "shop": "Shop",
  "shotType": "Shot Type",
  "simulator_capital_one": "Simulator",
  "simulator_capital_other": "Simulators",
  "simulator_one": "simulator",
  "simulator_other": "simulators",
  "stagedThrows": "Staged Throws",
  "stockShot": "Stock Shot",
  "techRack": "Tech Rack",
  "test_drive": "Test Drive",
  "throw": "Throw",
  "throw_capital_one": "Throw",
  "throw_capital_other": "Throws",
  "throw_one": "throw",
  "throw_other": "throws",
  "throw_set_capital_one": "Throw Set",
  "throw_set_capital_other": "Throw Sets",
  "throw_set_one": "throw set",
  "throw_set_other": "throw sets",
  "throwingHand": "Throwing Hand",
  "throwLab": "Throw Lab",
  "stockShots": "Stock Shots",
  "addStockShot": "Add Stock Shot",
  "backToStockShots": "Back to Stock Shots",
  "throwProfile": {
    "information": {
      "howToUseStockShots": {
        "heading": "How do I use them?",
        "info": [
          "Use stock shots to tune your virtual discs to their real flight characteristics using our <0>feature</0>.",
          "Collectively, stock shots are a complete profile of your disc golf game."
        ]
      },
      "whatAreStockShots": {
        "heading": "What is a \"stock shot\"?",
        "info": [
          "We define a <0>stock shot</0> as a disc golf shot that is a consistent part of your game.",
          "Each stock shot is a unique combination of:<1><2>throwing arm/hand</2><2>throwing style</2><2>shot type</2></1>"
        ]
      }
    },
    "instructions": {
      "complete": "",
      "setup": "",
      "review": "",
      "throw": "",
      "throwAmount": "Must record a minimum of 3 throws.",
      "throwNextStep": "In the next step, select {{minimum}} or more throws that best represent your stock shot."
    }
  },
  "throwStyle": "Throw Style",
  "throwTime": "Throw Time",
  "units": {
    "degrees": {
      "lower": "deg",
      "upper": "DEG"
    },
    "feet": {
      "lower": "ft",
      "upper": "FT"
    },
    "kmh": {
      "lower": "km/h",
      "upper": "KM/H"
    },
    "meters": {
      "lower": "m",
      "upper": "M"
    },
    "mph": {
      "lower": "mph",
      "upper": "MPH"
    },
    "rpm": {
      "lower": "rpm",
      "upper": "RPM"
    }
  },
  "user_management": "User Management",
  "wear": "Wear"
}
